import { Text, VStack } from "@chakra-ui/react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Portfolio from "../components/Portfolio"

function Home() {
    return <>
        <VStack textColor={'black'} background={'gray.200'} minHeight={'100vh'} display={'flex'}>
            <Header />
            <Portfolio />
            <Footer />
        </VStack>
    </>
}

export default Home