import { Center, Flex, Text } from "@chakra-ui/react";

function Footer() {
    return <>
        <Center w={'full'} backgroundColor={'gray.300'}>
            <Text>&#169; Copyright 2023 CoreBits LLC,</Text>
        </Center>
    </>
}

export default Footer