import { Card, Heading, HStack, Button, Image, Link, LinkOverlay, Text, VStack } from "@chakra-ui/react"

function Portfolio() {
    return <>
        <VStack flexGrow={1} align={'start'} width={'80vw'}>
            <Card w={'full'} padding={2} marginTop={'12'} backgroundColor={'white'} textColor={'black'} align={'start'}>
                <Heading size={'lg'}>Bills to Budget</Heading>

                <HStack align={'start'} textStyle={''} paddingX={'8'}>
                    <VStack align={'start'}>
                        <Text paddingTop={4}>
                            The simplest things you can do to improve your personal finances are to pay your bills on time every month,
                            and to create a spending plan for your money - it's easy to say, but hard to do regularly.  Bills to Budget can help!
                        </Text>
                        <Link paddingTop={'8'} href="https://www.billstobudget.com" target="_blank">
                            <Button textColor={'white'} backgroundColor={'green'}>Learn More</Button>
                        </Link>
                    </VStack>
                    <Image src='Bills.png' w={'40%'} />
                </HStack>

            </Card>

            <Link fontSize={'4xl'} fontWeight={'bold'} paddingTop={'12'} href="https://www.homemadeautomation.com" target="_blank">
                <Text>HomeMade Automation</Text>
            </Link>

            <Card w={'full'} padding={2} backgroundColor={'white'} textColor={'black'} align={'start'}>
                <Heading size={'lg'}>Signals for HomeKit</Heading>

                <HStack align={'start'} textStyle={''} paddingX={'8'}>
                    <VStack align={'start'}>
                        <Text paddingTop={4}>
                            Communication at home comes in many forms. Dinner time conversations. Casual silliness while getting ready in the morning. 
                            Yelling across the house when it's time to go. The frustrated knock on the bedroom door, when your cries go unheeded.
                        </Text>
                        <Link paddingTop={'8'} href="https://www.homemadeautomation.com/signals-for-homekit" target="_blank">
                            <Button backgroundColor={'yellow'}>Learn More</Button>
                        </Link>
                    </VStack>
                    <Image src='Signals.png' w={'40%'} />
                </HStack>

            </Card>

            <Card w={'full'} padding={2} backgroundColor={'white'} textColor={'black'} align={'start'}>
                <Heading size={'lg'}>Follow the Sun for HomeKit</Heading>

                <HStack align={'start'} textStyle={''} paddingX={'8'}>
                    <VStack align={'start'}>
                        <Text paddingTop={4}>
                            Light can have a huge impact on your day - cool, white light can get you moving and alert in the morning,
                            brighter lights after lunch can help you make it through the "afternoon slump", and dimmer, warmer lighting 
                            in the evening will help you wind down before bed.
                        </Text>
                        <Text paddingTop={4}>Let Follow the Sun for HomeKit help you get just the right light at just the right time.</Text>
                        <Link paddingTop={'8'} href="https://www.homemadeautomation.com/follow-the-sun-for-homekit" target="_blank">
                            <Button backgroundColor={'darkorange'}>Learn More</Button>
                        </Link>
                    </VStack>
                    <Image src='FTS.png' w={'40%'} />
                </HStack>
                
            </Card>
        </VStack>
    </> 
}

export default Portfolio