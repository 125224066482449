import { Flex, Heading, Spacer, Text, VStack } from "@chakra-ui/react";

function Header() {
    return <>
        <Flex w={'100vw'} padding={4} background={'peru'}>
            <VStack align={'start'} spacing={0}>
                <Heading>CoreBits</Heading>
                <Text textColor={'gray.100'}>Purveyors of Fine Software</Text>
            </VStack>
            <Spacer />
        </Flex>
    </>
}

export default Header